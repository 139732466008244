import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout"
import SearchEngineOptimization from "../components/SearchEngineOptimization"
import styled from "styled-components"

const Header1 = styled.h1`
    text-align: center;
    margin-top: 4rem;
`

const LeftAlignedParagraph = styled.p`
    text-align: left;
    align-items: flex-start;
`

const ContentLeftWrapper = styled.div`
    display: block;
`

const PrivacyPage = ({ intl }) => {
    return (
        <Layout pageInfo={{ pageName: "Privacy" }}>
            <SearchEngineOptimization
                lang={intl.locale}
                title={intl.formatMessage({ id: "pages.privacy.seo.title" })}
            />
            <ContentLeftWrapper>
                <Header1>
                    <FormattedMessage id="pages.privacy.title" />
                </Header1>
                <h4>
                    <FormattedMessage id="pages.privacy.updated_text" />
                </h4>
                <LeftAlignedParagraph>
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                </LeftAlignedParagraph>
                <Header1>Interpretation and Definitions</Header1>
                <h2>Interpretation</h2>
                <LeftAlignedParagraph>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                </LeftAlignedParagraph>
                <h2>Definitions</h2>
                <LeftAlignedParagraph>
                    For the purposes of this Privacy Policy:
                </LeftAlignedParagraph>
                <ul>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>Application:</strong> means the software
                            program provided by the Company downloaded by You on
                            any electronic device, named Satimoto
                        </LeftAlignedParagraph>
                    </li>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>Company:</strong> (referred to as either
                            &quot;the Company&quot;, &quot;We&quot;,
                            &quot;Us&quot; or &quot;Our&quot; in this Agreement)
                            refers to Satimoto.
                        </LeftAlignedParagraph>
                    </li>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>Personal Data:</strong> is any information
                            that relates to an identified or identifiable
                            individual.
                        </LeftAlignedParagraph>
                    </li>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>Service:</strong> refers to the Application.
                        </LeftAlignedParagraph>
                    </li>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>Service Provider:</strong> means any natural
                            or legal person who processes the data on behalf of
                            the Company. It refers to third-party companies or
                            individuals employed by the Company to facilitate
                            the Service, to provide the Service on behalf of the
                            Company, to perform services related to the Service
                            or to assist the Company in analyzing how the
                            Service is used.
                        </LeftAlignedParagraph>
                    </li>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>Usage Data:</strong> refers to data
                            collected automatically, either generated by the use
                            of the Service or from the Service infrastructure
                            itself (for example, the duration of a page visit).
                        </LeftAlignedParagraph>
                    </li>
                    <li>
                        <LeftAlignedParagraph>
                            <strong>You:</strong> means the individual accessing
                            or using the Service, or the company, or other legal
                            entity on behalf of which such individual is
                            accessing or using the Service, as applicable.
                        </LeftAlignedParagraph>
                    </li>
                </ul>
                <Header1>Collecting and Using Your Personal Data</Header1>
                <h4>Types of Data Collected</h4>
                <LeftAlignedParagraph>None.</LeftAlignedParagraph>
                <Header1>Links to Other Websites</Header1>
                <LeftAlignedParagraph>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                </LeftAlignedParagraph>
                <LeftAlignedParagraph>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                </LeftAlignedParagraph>
                <Header1>Changes to this Privacy Policy</Header1>
                <LeftAlignedParagraph>
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                </LeftAlignedParagraph>
                <LeftAlignedParagraph>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                </LeftAlignedParagraph>
                <Header1>Contact Us</Header1>
                <LeftAlignedParagraph>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                </LeftAlignedParagraph>
                <a href="mailto:hello@satimoto.com">hello@satimoto.com</a>
            </ContentLeftWrapper>
        </Layout>
    )
}

export default injectIntl(PrivacyPage)
